import { withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layouts/layout';
import BreadCrumb from '../components/ProductMain/BreadCrumb';
import ProductMain from '../components/ProductMain/ProductMain';

const serum = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rose And Gold Face Serum With Vitamin C For Skin Brightening</title>
        <meta name="facebook-domain-verification" content="7zavsnqe2ti2yx2faj448nyhtkrxg5" />
        <meta name="title" content="Rose And Gold Face Serum With Vitamin C For Skin Brightening" />
        <meta
          name="description"
          content="Buy Rose And Gold Face Serum. Expertly formulated with the most stable derivative of Vitamin C. It is fast-absorbing and Natural Moisturizing Factors"
        />
        <meta
          name="keywords"
          content="Rose And Gold Face Serum, Face Serum With Vitamin C, Non-oily Face Serum, Moisturizers, Skincare, Serums And Essence"
        />
        {/* Global site tag (gtag.js) - Google Analytics  */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-1LB4M0W7V9" />
        <script src={withPrefix('js/script.js')} type="text/javascript" />
      </Helmet>
      <Layout>
        <BreadCrumb productSelected="serum" />
        <ProductMain productSelected="serum" />
      </Layout>
    </>
  );
};

export default serum;
